.root {
  color: #ffffff;
  font-family: "Gilroy SemiBold";
  font-size: 14px;
}
.MuiTextField-root {
  /* color: #ffffff; */
  font-family: "Gilroy SemiBold";
  font-size: 14px;
}
