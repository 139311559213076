.price-header-table {
  display: table;
  width: auto;
  background-color: #fff;
  font-size: 12px !important;
  margin: auto;
  min-width: 280px;
}

div.price-header-table > div:nth-of-type(odd) {
  background: #ddebf7;
}
