.root {
  /* background-color: rgb( 231,236,238 ); */
  background-color: rgb(228, 233, 235);
}
.link-deal {
  text-decoration: none;
  position: relative;
  z-index: 5;
}

.link-deal:hover {
  color: #22b0ff;
  transition: all 0.3s ease-in-out;
}

.link-deal::before {
  content: "";
  background-color: hsla(196, 61%, 58%, 0.231);
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 2px;
  transition: all 0.3s ease-in-out;
  z-index: 1;
}

.link-deal:hover::before {
  bottom: 0;
  height: 100%;
}
