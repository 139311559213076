  .graph-container {

    .past,
    .paid,
    .Paid {
        background: #22B0FF;
    }
    .Due {
        background: #007bff;
    }
    .question-mark {
        background: #e8e8e8;
    }
    .Overdue {
        background: #FF0000;
    }
    .Overdue90 {
        background: #d80000;
    }
  }

  .thisyear-future.thisYearGroup {
    border-left: 0.5px solid #D9D9D9;
    border-right: 0.5px solid #D9D9D9;
  }