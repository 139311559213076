.dt-price-card {
  height: 375px;
  width: 250px;
  text-align: center;
  margin: auto;
  margin: 10px;
  position: relative;
  background-color: #fff !important;
  border-radius: 20px !important;
}

.dt-product-name {
  font-size: 50px;
  font-family: "Gilroy SemiBold";
  margin: 0;
  color: #171720 !important;
}

.dt-product-info {
  color: #171720 !important;
  font-family: "Gilroy SemiBold" !important;
  font-size: 20px !important;
}

.dt-price {
  font-family: "Gilroy Medium" !important;
  /* color: #22b0ff !important; */
}

.change-text {
  margin: 0;
  font-size: 0.75rem;
  font-family: "Gilroy light" !important;
  font-weight: 100;
  line-height: 1.43;
}
