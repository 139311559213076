.timeline-container-scroll {
  width: 100%;
  height: 98%;
  position: relative;
  overflow-y: auto;
  scrollbar-width: none;
  scroll-snap-type: y mandatory;
}

.timeline-container-scroll::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}
