body {
  background-color: #e4e9eb !important;
}

.title {
  color: rgb(23, 23, 32);
}

.back-button {
  border: 1px solid #a6a6a6;
  display: inline-block;
  color: #a6a6a6;
  border-radius: 6px;
  margin-right: 1em;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  svg {
    height: 0.5em !important;
    margin: 0 auto; /* to center it inside parent */
    margin-left: 0.2em;
    margin-top: 0.2em;
    margin-bottom: 0.2em;
  }
}

.back-button:hover {
  color: #303030 !important;
}

.breadcrumbs a,
.breadcrumbs span {
  color: #a6a6a6;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.breadcrumbs a:hover {
  color: #303030;
}

.breadcrumbs a:last-child {
  color: #303030;
}

.deal-card {
  color: #303030;

  .grid-info {
    margin-top: 1em;

    .label-text:nth-child(2) {
      margin-left: 2em;
    }

    .text {
      margin-left: 2em;
    }
  }
}

.line-break {
  border-bottom: 1px solid #f4eced;
}

.label-text {
  color: #a6a6a6;
  white-space: nowrap;
}

.text {
  margin-left: 2em;
}

.text-in-table {
  margin-left: 2em;
}

.invoice-card {
  color: #303030;

  .invoice {
    border: 1px solid #a4a4a4;
    padding: 1em;
  }
}

.status-icon-open,
.status-icon-closed,
.status-icon-outstanding,
.status-icon-warning {
  background-color: #5eb0f2;
  border-radius: 8px;
  text-align: center;
  width: auto;
  display: inline-block;
}

.status-icon-open p,
.status-icon-closed p,
.status-icon-outstanding p,
.status-icon-warning p {
  text-align: center;
  vertical-align: middle;
  margin: 0;
  padding: 0.2em 0.8em;
  color: white;
}

.comment-block {
  border-radius: 15px;
  background-color: white;
  color: #303030;
  padding: 1em;
  position: -webkit-sticky;
  position: sticky;
  height: 65vh;
  top: 0;
  overflow: hidden;
  align-self: flex-start;

  .form,
  .form div {
    background-color: white !important;
  }
}

.comment-block-65vh {
  height: 65vh;
}

.comment-block-deal-detail {
  border-radius: 1rem;
  background-color: white;
  padding: 1em;

  .form,
  .form div {
    background-color: white !important;
  }
}

.comment-card {
  border: 1px solid #f4eced;
  border-radius: 15px;
  padding: 1em;
  position: relative;
}

.author {
  display: flex;
  width: 100%;
  align-items: center;

  .author-name {
    margin: 0em;
    color: #303030;
  }

  span {
    font-size: 0.8em;
    color: #a6a6a6;
  }
}

.comment-card p {
  margin-left: 1em;
}

.comment-card-content {
  margin-left: 1em;
}

.flag-icon {
  position: absolute;
  background-color: #dc2334;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.container-scroll {
  margin-top: 10px;
  width: 100%;
  height: 77%;
  position: relative;
  overflow-y: auto;
  scrollbar-width: none;
  scroll-snap-type: y mandatory;

  .comment-card {
    scroll-snap-align: start;
  }

  &::webkit-scrollbar {
    display: none;
  }

  @media (max-height: 900px) {
    height: 74%;
  }

  @media (max-height: 800px) {
    height: 70%;
  }

  @media (max-height: 700px) {
    height: 68%;
  }

  @media (max-height: 650px) {
    height: 65%;
  }

  @media (max-height: 600px) {
    height: 63%;
  }

  @media (max-height: 500px) {
    height: 55%;
  }

  @media (max-height: 400px) {
    height: 40%;
  }
}

.comment-block-deal-detail form {
  border-radius: 1rem;

  div:first-child {
    width: 100%;
    display: block;
    box-sizing: border-box;

    div:first-child {
      max-width: 100%;

      input {
        width: 85%;
      }
    }

    div:nth-child(2) {
      max-width: 100%;
    }

    div:nth-child(3) {
      max-width: 100%;
    }
  }

  svg {
    fill: #2196f3;
  }
}

.MuiInput-underline::before {
  border-bottom: 1px solid #2196f3 !important;
}

.MuiInputAdornment-positionEnd {
  margin-left: 100% !important;
  position: absolute;
  top: 10px !important;
  right: 0em !important;
}

table {
  tr {
    td:first-child {
      width: 10em;
    }
    td {
      width: 15em;
    }
    .td-long-child {
      width: 30em !important;
    }
    .td-short-child {
      width: 15em;
    }
  }
}

table#ClientPageOverview {
  tr {
    td:first-child {
      width: 24px;
    }
    td:nth-of-type(2) {
      width: 240px;
    }
  }
}

.small-table {
  tr {
    td:first-child {
      width: 12em;
    }
    td {
      width: 15em;
    }
  }
}
