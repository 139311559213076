.priceheaderrow-category {
  float: left; /* fix for  buggy browsers */
  display: table-column;
  width: 68%;
}
.priceheaderrow-price {
  float: left; /* fix for  buggy browsers */
  display: table-column;
  width: 16%;
  text-align: center;
}
.priceheaderrow-row {
  display: table-row;
  width: auto;
  clear: both;
}
.priceheaderrow {
  font-family: "LibreFranklin Medium";
  padding: 3px;
  background-color: #11253f;
  color: white;
  font-size: 11px;
}
