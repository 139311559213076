.MuiPopover-paper {
  background-color: #171720 !important;
}

.see-more-link {
  padding: 8px;
  border-top: 1px solid white;
  color: white !important;
  transition: all 200ms ease;
}

.see-more-link:hover {
  color: #22b0ff !important;
}
