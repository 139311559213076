@tailwind base;
@tailwind components;
@tailwind utilities;

@import "table/table.scss";
@import "ppbar.scss";

html {
  background-color: #e4e9eb;
  scrollbar-width: thin;
}

body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
.root {
  background-color: rgb(228, 233, 235) !important;
  color: #171720 !important;
  width: 100vw;
}

#root {
  position: relative;
}

.root {
  padding: 3em;
  position: relative;

  @media screen and (max-width: 768px) {
    padding: 1.5em;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

textarea {
  resize: none;
}

textarea:focus {
  outline: none !important;
}

.modal-product {
  background-color: #e4e9eb !important;
  color: #171720 !important;
  border: 1px solid #e4e9eb;
  border-radius: 10px;
}

.MuiAutocomplete-popper .MuiPaper-root {
  color: #171720 !important;
  background-color: white !important;
}

.MuiAutocomplete-option[data-focus="true"] {
  background-color: #86cef3 !important;
}

.MuiAutocomplete-option {
  min-height: auto;
  border-bottom: 1px solid #e4e9eb;
}

.select {
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;

  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding-right: 0px !important;
  }
  .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"],
  .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding-right: 0px !important;
  }
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    padding-right: 0px !important;
  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 0px !important;
  }
  .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot,
  .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    padding-right: 0px !important;
  }

  font-size: 10px !important;
  color: #364e66 !important;

  .MuiSvgIcon-root {
    fill: #9ba3ae !important;
  }

  .MuiAutocomplete-clearIndicator {
    visibility: visible !important;
    display: none !important;
    background-color: white;
    padding: 0px 9px !important;
    border: 1px solid white !important;
    border-radius: 0 !important;
    width: 2em;
    position: relative;
    top: -6px;
  }

  .MuiAutocomplete-inputRoot {
    flex-wrap: nowrap !important;
  }

  .MuiFormControl-root {
    display: block !important;
  }

  input[type="text"] {
    color: #364e66 !important;
    border-right: 1px solid white !important;
    border-top: 1px solid white !important;
    border-bottom: 1px solid white !important;
    border-radius: 0 10px 10px 0px;
    background-color: white;
    width: 100% !important;
    padding: 3.6px 4px !important;
    margin: 5px 0 !important;
    opacity: 1;

    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
  }
}

.date {
  input[type="text"] {
    opacity: 0.5 !important;
  }
}

#chart {
  //height: calc(40vh - 100px); /** output container is small for display */
  width: calc(100% - 100px);
  display: block;
  position: relative;

  @media screen and (max-width: 1279px) {
    width: calc(100% - 30px);
  }

  .rect-stacked {
    display: inline-block;
  }

  .today {
    border-radius: 0px;
  }
}

#clientListChart {
  pointer-events: none;
  position: relative;
  width: 100%;

  .rect-stacked {
    display: inline-block;
  }

  .today {
    border-radius: 0px;
  }
}

#clientListChart > * {
  margin: 0;
}

.exprevlist {
  #chart {
    width: 100%;
    display: block;
    position: relative;

    .rect-stacked {
      display: inline-block;
    }

    .today {
      border-radius: 0px;
    }
  }
}
.selected {
  font-size: 10px !important;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;

  .MuiSvgIcon-root {
    fill: #9ba3ae !important;
  }

  .MuiAutocomplete-clearIndicator {
    visibility: visible !important;
    background-color: #d8edf4;
    padding: 0px 9px !important;
    width: 1em;
    position: relative;
    top: 0px;
    border-right: 1px solid #86cef3 !important;
    border-top: 1px solid #86cef3 !important;
    border-bottom: 1px solid #86cef3 !important;
    border-radius: 0 10px 10px 0px;
  }

  .MuiAutocomplete-clearIndicator:hover {
    visibility: visible !important;
    background-color: #d8edf4;
    padding: 0px 9px !important;
    width: 1em;
    position: relative;
    top: 0px;
    color: "#364E66" !important;
    border-right: 1px solid #86cef3 !important;
    border-top: 1px solid #86cef3 !important;
    border-bottom: 1px solid #86cef3 !important;
    border-radius: 0 10px 10px 0px;
  }

  .MuiFormControl-root {
    display: block !important;
  }

  .MuiAutocomplete-inputRoot {
    flex-wrap: nowrap !important;
  }

  input[type="text"] {
    color: #364e66;
    border-left: 1px solid #86cef3 !important;
    border-top: 1px solid #86cef3 !important;
    border-bottom: 1px solid #86cef3 !important;
    border-right: 0;
    background-color: #d8edf4;
    width: 100% !important;
    padding: 3.6px 4px !important;
    margin: 5px 0 !important;
    opacity: 1;
    border-radius: 10px 0px 0px 10px;

    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
  }

  .MuiAutocomplete-endAdornment {
    position: static !important;
    display: flex !important;
  }

  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding-right: 0px !important;
  }
  .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"],
  .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding-right: 0px !important;
  }
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    padding-right: 0px !important;
  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 0px !important;
  }
  .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot,
  .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    padding-right: 0px !important;
  }
}

.deal-filter .text-filter {
  .MuiOutlinedInput-root {
    border: 1px solid #e1e1e1 !important;
    padding-left: 0 !important;
    border-radius: 10px !important;
  }

  .MuiOutlinedInput-input {
    padding: 3.6px 4px !important;
    margin: 5px 0 !important;
  }

  .MuiInputBase-root {
    display: flex;
  }

  .MuiSvgIcon-root {
    fill: #9ba3ae !important;
    width: 0.8em !important;
    height: 0.8em !important;
    position: relative;
    top: -2px;
  }

  .placeholder {
    color: #9ba3ae !important;
  }
}

.deal-filter .empty-text {
  input[type="text"] {
    color: #364e66 !important;
    width: 5em !important;
    border-left: 1px solid #ffffff !important;
    border-top: 1px solid #ffffff !important;
    border-bottom: 1px solid #ffffff !important;
    border-right: 0;
    padding-left: 0 !important;
    border-radius: 10px !important;
    margin-right: 1em;

    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
  }

  .clear-icon {
    display: none !important;
  }
}

.deal-filter .full-text {
  input[type="text"] {
    color: #364e66 !important;
    width: 5em !important;
    background-color: #d8edf4 !important;
    border: 1px solid #86cef3 !important;
    border-right: 0px !important;
    padding-left: 0 !important;
    border-radius: 10px 0px 0px 10px;
    text-align: center;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
  }

  .clear-icon {
    visibility: visible !important;
    background-color: #d8edf4;
    padding: 2.5px 1px !important;
    position: relative;
    top: 0px;
    color: "#364E66" !important;
    border-right: 1px solid #86cef3 !important;
    border-top: 1px solid #86cef3 !important;
    border-bottom: 1px solid #86cef3 !important;
    border-radius: 0 10px 10px 0px;
  }
}

@-webkit-keyframes slide {
  100% {
    left: 0;
  }
}

@keyframes slide {
  100% {
    left: 0;
  }
}

.avatars {
  display: inline-flex;
  margin-left: 5px;
}

.avatar {
  position: relative;
  border: 1px solid #fff;
  border-radius: 50%;
  overflow: hidden;
}

.avatar {
  margin-left: -5px;
}

.avatar img {
  width: 30px;
  height: 30px;
  display: block;
}

.avatarHover:after {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 10px solid black;
  opacity: 0.6;
  content: " ";
  position: absolute;
  top: -10px;
  right: 12px;
}

div.tooltip {
  position: absolute;
  text-align: center;
  width: 12em;
  height: auto;
  padding: 8px;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: 0px;
  border-radius: 8px;
  pointer-events: none;
  z-index: 999999999;
}

div.barWrapper {
  pointer-events: all;
  position: relative;
}

div.ClientList {
  pointer-events: all;
  position: absolute;
  text-align: left;
  min-width: 280px;
  width: fit-content;
  height: auto;
  padding: 10px;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: 0px;
  border-radius: 10px;
  pointer-events: none;
  z-index: 9999;
  // overflow: hidden;
}

@media only screen and (max-width: 600px) {
  div.ClientList {
    left: 0 !important;
    width: 100%;
  }

  div.ClientList:after {
    display: none;
  }
}

div.ClientList .client-list-heading {
  margin-top: 14px;
  margin-bottom: 2px;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

div.ClientList .client-list-heading p:first-of-type,
div.ClientList .client-list-heading p:nth-of-type(2) {
  font-weight: bold;
  padding-left: 2px;
}

div.ClientList .client-list-heading p:nth-of-type(2) {
  padding-left: 10px;
}

div.ClientList .client-list-heading p:nth-of-type(3) {
  position: absolute;
  font-size: 12px;
  border-radius: 6px 0px;
  background-color: rgb(181, 181, 181);
  // border: 1px solid #DDDDDD;
  margin-left: 0px;
  top: 0px;
  left: 0px;
  text-align: right;
  padding: 2px 6px;
}

div.ClientList .client-list-container > div {
  display: flex;
  justify-content: left;
  padding-left: 4px;
}

div.ClientList .client-list-container > div p:not(:first-of-type) {
  padding-left: 8px;
}

div.ClientList .client-list-container > div p:first-of-type,
div.ClientList .client-list-heading p:first-of-type {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 132px;
  max-width: 132px;
}

.shine {
  display: inline-block;
  -webkit-mask: linear-gradient(-50deg, #fff 60%, rgba(255, 255, 255, 0.09), #fff 70%) right/300% 100%;
  background-repeat: no-repeat !important;
  animation: shimmer 4s infinite;
}

@keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
}

.grid-card-container {
  max-width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(250px, 100%), 1fr));
}

.checkmark {
  border-radius: 50%;
  display: block;
  stroke-width: 4;
  stroke: white;
  stroke-miterlimit: 10;
  stroke-dashoffset: 0;
}

.toggle {
  display: flex;
  justify-content: center;
  align-items: center;

  input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
    transition: all 0.1s;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 30px;
    height: 15px;
    background: rgb(229, 229, 229);
    display: block;
    border-radius: 100px;
    position: relative;
    transition: all 0.2s;
  }

  label:after {
    content: "";
    position: absolute;
    top: -2.5px;
    left: 0px;
    width: 20px;
    height: 20px;
    background: rgb(201, 200, 200);
    border-radius: 90px;
    transition: all 0.2s;
  }

  input:checked + label {
    background: #ffccd1;
    transition: all 0.1s;
  }

  input:checked + label:after {
    //transform: translateX(-100%);
    transition: all 0.1s;
    animation: moveit 100ms forwards;
    will-change: transform;
  }

  @keyframes moveit {
    0% {
      transform: translateX(0%) scale3d(0.96, 0.96, 1);
      background: #fff;
    }
    100% {
      transform: translateX(70%) scale3d(1, 1, 1);
      background: #dc2334;
    }
  }
}

div[class^="css-4-0-1"] {
  max-height: 20rem;
}

.w-full__suggestions {
  border-radius: 0.5rem;
  z-index: 100 !important;
}

.modal {
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}
// badge hack to match new tailwind badge
.MuiBadge-badge {
  border: 2px solid;
  border-color: rgb(17 24 39);
  width: 24px;
  height: 24px !important;
  border-radius: 9999px !important;
  font-weight: bold !important;
}
.MuiBadge-colorSecondary {
  background-color: #ef4444 !important;
}

.container {
  display: flex;
}

.text-input {
  width: 20%;
}
.modal {
  z-index: 5;
}
.modal-card {
  background-color: rgb(228, 233, 235);
  margin: auto auto;
  width: 50%;
  padding: 2em;
  border-radius: 5px;
  position: relative;
  top: 15%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.modal-header {
  display: flex;
  justify-content: flex-end;
}

.modal-content svg,
.modal-content label,
.modal-content input {
  color: #22b0ff !important;
}

.MuiInput-underline::before {
  border-bottom: 1px solid #171720 !important;
}

.modal-button {
  border-radius: 100%;
  border: 1px solid transparent;
  background-color: rgb(228, 233, 235) !important;
  cursor: pointer;
}

.button {
  width: 10em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 4px;
  color: rgb(228, 233, 235) !important;
  font-size: 1rem;
  font-family: Gilroy SemiBold;
  font-weight: 400;
  line-height: 1;
  cursor: pointer;
  background-color: rgb(34, 176, 255) !important;
  margin-left: 1em;
  -webkit-box-shadow: none !important ;
  box-shadow: none !important ;

  .hidden {
    background-color: rgb(166, 166, 166) !important;
  }
}

.button-container,
.prev-next-wrapper {
  display: flex;
}

.button-container {
  justify-content: space-between;
  margin-top: auto;
}

.button-container .button {
  width: 5em;
  padding: 0.6em 0em;
  -moz-transition: background-color 0.2s ease-out;
  -o-transition: background-color 0.2s ease-out;
  transition: background-color 0.2s ease-out;
}

.button-container .button:hover {
  background-color: #115980 !important;
}

.button-container .hidden:hover {
  background-color: rgb(166, 166, 166) !important;
  cursor: auto;
}

.button-container .button:first-child {
  margin-left: 0;
}

.svg {
  width: 20%;
}

th,
tr,
td {
  color: #171720 !important;
}

.pagination button {
  color: #171720;
  border: 1px solid transparent;
  border-radius: 4px;
  font-weight: 600;
  padding: 0.4em 0.4em;
  margin: 0 0.2em;
  margin-top: 1em;
  cursor: pointer;
}

.pagination button:nth-child(2),
.pagination button:nth-child(3) {
  color: #171720 !important;
  border: 1px solid transparent;
  border-radius: 4px;
  font-weight: 600;
  padding: 0.4em 0.8em;
  margin: 0 0.2em;
  margin-top: 1em;
  cursor: pointer;
}

.pagination button:hover {
  color: white;
  background-color: rgb(34, 176, 255);
  -moz-transition: background-color 0.2s ease-out;
  -o-transition: background-color 0.2s ease-out;
  transition: background-color 0.2s ease-out;
}

.arrow-div .arrow {
  display: inline-block;
}

.arrow svg {
  height: 0.7em;
  width: 0.7em;
}

th > div {
  display: flex;
}

.expanded {
  border-bottom: 1px solid black;
}

.pagination {
  color: black !important;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 1em;
}

.pagination input {
  color: black !important;
  border-bottom-color: #171720 !important;
}
.pagination #outlined-select-currency {
  color: black !important;
}
.pagination .number-input {
  width: 4em;
}

.container-input {
  display: flex;
  align-items: center;
}

.container-input span {
  margin-right: 1em;
}

.pagination:not(.clientPagePagination) > div:first-child {
  display: flex;
  justify-content: center;
  padding-bottom: 0.4em;
  margin-right: 2em;
}

.MuiInput-underline {
  border: none;
  cursor: pointer;
  margin-right: 1em;
}

.Muiinput-underline:hover {
  border: none;
}

.text-field-container {
  padding: 12px;
  width: 21em;
  position: relative;
  background-color: inherit;
}

.text-field {
  height: 3.5em;
  width: 100%;
  border: 0;
  border: 1px solid #171720;
  border-radius: 5px;
  background-color: inherit;
  padding: 0 10px;
  font-size: 1rem;
  font-family: Gilroy SemiBold;
  font-weight: 400;
}

.text-field:focus {
  outline: #171720;
  border: 2px solid #171720;
}

.field-label {
  position: absolute;
  background-color: inherit;
  top: 36%;
  left: 25px;
  padding-right: 5px;
  padding-left: 5px;
  text-align: center;
  color: #22b0ff;
  font-size: 1rem;
}

.formfield-group {
  width: 2000;
  background-color: "white";
  border-radius: 5;
  padding: 10;
}

.checkbox:hover input ~ .CheckboxCheckmark {
  background-color: white;
}

.checkbox input:checked ~ .CheckboxCheckmark {
  background-color: white;
}

.checkbox:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox input:checked ~ .CheckboxCheckmark:after {
  display: block;
}

.checkbox .CheckboxCheckmark:after {
  content: "";
  left: 25px;
  top: 6px;
  width: 3px;
  height: 7px;
  border: solid #22b0ff;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  display: none;
  margin-left: 3px;
  margin-top: 1px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
  border-radius: 0px;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 6px;
  height: 95%;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 0px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
  background-color: rgb(219, 219, 222);
  /* Hide scrollbar for Chrome, Safari and Opera */
}

.no-scrollbar::-webkit-scrollbar {
  width: 5px;
}

.no-scrollbar::-webkit-scrollbar-thumb {
  background: rgb(219, 219, 222);
  padding-top: 10px;
}

.addBackdropModal > div {
  backdrop-filter: blur(2px);
}

.formGenerator > div::-webkit-scrollbar,
.formGenerator div:has(ul[role="listbox"])::-webkit-scrollbar {
  width: 0px;
}

.formGenerator input[type="date"] {
  position: relative;
  font-size: 0px;
}

// .formGenerator span {
//   background-color: transparent;
// }

.formGenerator input[type="number"]:focus,
.formGenerator input[type="text"]:focus,
.formGenerator input[type="date"]:focus {
  box-shadow: 0px 0px 5px #bcdfeb;
}

#externalLinskWrapper {
  height: fit-content;
  position: relative;
}

#externalLinksWrapper > div {
  height: calc(50vh - 32px);
}

#externalLinksWrapper > div:nth-of-type(1) {
  background-color: #f8f8f8;
  color: #202017;
}

#externalLinksWrapper > div:nth-of-type(1) a,
#externalLinksWrapper > div:nth-of-type(1) p {
  color: #202017;
}

#externalLinksWrapper > div:nth-of-type(1) a:hover {
  background-color: #afafaf;
  color: #f8f8f8;
}

#externalLinksWrapper > div:nth-of-type(2) {
  background-color: #22b0ff;
}

#externalLinksWrapper > div:nth-of-type(2) a:hover {
  background-color: white;
  color: #22b0ff;
}

#externalLinksWrapper > div:nth-of-type(3) {
  background-color: #115980;
}

#externalLinksWrapper > div:nth-of-type(3) a:hover {
  background-color: white;
  color: #115980;
}

#externalLinksWrapper > div:nth-of-type(4) {
  background-color: #e4e9eb;
}

#externalLinksWrapper > div:nth-of-type(5) {
  background-color: #3f3f46;
}

#externalLinksWrapper > div:nth-of-type(6),
#externalLinksWrapper > div:nth-of-type(1) a {
  background-color: #ffffff;
}

#externalLinksWrapper > div:nth-of-type(4) p,
#externalLinksWrapper > div:nth-of-type(4) a,
#externalLinksWrapper > div:nth-of-type(6) p,
#externalLinksWrapper > div:nth-of-type(6) a {
  color: #3c3c3c;
  border-color: #3c3c3c;
}

#externalLinksWrapper > div:nth-of-type(4) a:hover,
#externalLinksWrapper > div:nth-of-type(6) a:hover {
  background-color: #3c3c3c;
  color: #e4e9eb;
}

#externalLinksWrapper > div:nth-of-type(6) a:hover {
  color: #ffffff;
}

#externalLinksWrapper > div:nth-of-type(5) p,
#externalLinksWrapper > div:nth-of-type(5) a {
  color: white;
  border-color: white;
}

#externalLinksWrapper > div:nth-of-type(5) a:hover {
  background-color: white;
  color: #3c3c3c;
}

.zoomedOut {
  transition: all 0.3s ease-out; /* Apply a smooth transition effect */
}

.zoomed {
  transition: all 0.3s ease-in; /* Apply a smooth transition effect */
}

.expRev-volume::-webkit-inner-spin-button,
.expRev-volume::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.client-two {
  position: relative;
}

.client-two:before {
  content: "";
  position: absolute;
  top: 18px;
  left: -16px;
  width: 16px;
  height: 1px;
  background: rgb(222, 222, 222);
}

@media screen and (max-width: 640px) {
  .client-two:before {
    content: "";
    position: absolute;
    top: -16px;
    left: 50%;
    width: 1px;
    height: 16px;
    background: rgb(222, 222, 222);
    transform: translateX(-50%);
  }
}
