.boe-outer {
  /* width: 1024px; */
  height: 768px;
  background-color: white;
  padding-top: 20px;
  overflow: auto;
}

.boe-hero {
  padding-right: 20px;
  padding-left: 20px;
  width: 100%;
}

.boe-update-side {
  position: relative;
  text-align: right;
  padding-right: 20px;
  font-family: "Gilroy Light" !important;
  margin-top: 5px;
}

.table-parent {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding-left: 5%;
  padding-right: 5%;
  font-family: "LibreFranklin Medium" !important;
}

@media only screen and (max-width: 600px) {
  .table-parent {
    display: block;
    margin-top: 30px;
    padding-left: 5%;
    padding-right: 5%;
  }
}
