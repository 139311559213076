.pricerow-product {
  float: left; /* fix for  buggy browsers */
  display: table-column;
  width: 190px;
}
.pricerow-price {
  float: left; /* fix for  buggy browsers */
  display: table-column;
  width: 50px;
}
.pricerow-row {
  display: table-row;
  width: auto;
  clear: both;
}

.pricerow {
  padding: 3px;
  font-size: 11px;
  font-family: "LibreFranklin Medium";
}
