.form {
  /* background-color: #ffffff; */
  /* background-color: #8ad9ff; */
  /* background-color: #171720; */
  /* background-color: #115980; */
  /* background-color: #e7ecee; */
  background-color: #e4e9eb;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
  font-family: "Gilroy SemiBold";
  font-size: 14px;
}

.form-deal-note {
  font-family: "Gilroy SemiBold";
  font-size: 14px;
  background-color: white;
}

.form-field {
  /* background-color: #22b0ff; */
  /* background-color: #8ad9ff; */
  /* background-color: #171720; */
  /* background-color: #115980; */
  /* background-color: #e7ecee; */
  background-color: #e4e9eb;
  color: #171720;
}

.form-deal-note .form-field {
  background-color: white;
}
