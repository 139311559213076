table {
  font-family: Gilroy Semibold;
  font-weight: 400;
  color: #171720;
  text-rendering: optimizeLegibility;

  .label-text {
    color: #a6a6a6;
    white-space: nowrap;
  }
}
