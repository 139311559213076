@keyframes pulseBorder {
  50% {
    border: solid 2px #fff;
  }
}

@-webkit-keyframes pulseBorder {
  50% {
    border: solid 2px #fff;
  }
}

@keyframes pulseBorderGreen {
  50% {
    border: solid 2px #07922a;
  }
}

@-webkit-keyframes pulseBorderGreen {
  50% {
    border: solid 2px #07922a;
  }
}

@keyframes pulseCardGreen {
  50% {
    background-color: #104f22;
  }
}

@-webkit-keyframes pulseCardGreen {
  50% {
    background-color: #104f22;
  }
}

@keyframes pulseBorderRed {
  50% {
    border: solid 2px #d61616;
  }
}

@-webkit-keyframes pulseBorderRed {
  50% {
    border: solid 2px #d61616;
  }
}

@keyframes pulseCardRed {
  50% {
    background-color: #7b0a10;
  }
}

@-webkit-keyframes pulseCardRed {
  50% {
    background-color: #7b0a10;
  }
}

.cardAgeStage1 {
  border: solid 2px #e5eaec;
  animation: pulseBorder 2s infinite;
  -webkit-animation: pulseBorder 2s infinite;
}

.cardAgeStage1RedBorder {
  border: solid 2px #e5eaec;
  animation: pulseBorderRed 2s infinite;
  -webkit-animation: pulseBorderRed 2s infinite;

  /* background-color: #7b0a10 !important; */
}

.cardAgeStage1GreenBorder {
  border: solid 2px #e5eaec;
  animation: pulseBorderGreen 2s infinite;
  -webkit-animation: pulseBorderGreen 2s infinite;

  /* background-color: #104f22 !important; */
}

.cardAgeStage2Red {
  /* border: solid 2px #fff !important; */

  background-color: #7b0a10 !important;
}
.cardAgeStage2Green {
  /* border: solid 2px #fff !important; */

  background-color: #104f22 !important;
}
.cardAgeStage2 {
  /* border: solid 2px #e4e9eb !important; */
  color: "#171720" !important;
}

.cardAgeStage2RedBorder {
  border: solid 2px #d61616 !important;
  color: "#171720" !important;
}

.cardAgeStage2GreenBorder {
  border: solid 2px #07922a !important;
}

.cardAgeStage3 {
}

.cardAgeStage4 {
  opacity: 0.5;
  /* border: solid 1px #fff !important; */
  color: rgb(242, 200, 15, 0.5) !important;
}

.initialsBorder {
  border: solid 1px rgba(255, 255, 255, 0.672) !important;
}

.cardWrapper {
  width: 90%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 20px;
}

.priceCard {
  height: 360px;
  width: 250px;
  text-align: center;
  margin: auto;
  margin: 10px;
  position: relative;
}

.initials {
  padding: 5px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  -webkit-border-radius: 5px;
  /* border-width: 1px !important; */
}

.nudgeButton {
  width: 10px !important;
  font-size: 9px !important;
  min-width: 38px !important;
  margin-left: 0.5px !important;
  margin-right: 0.5px !important;
  color: white !important;
}

.savePriceButton {
  width: 75px !important;
  font-size: 11px !important;
  min-width: 38px !important;
  margin-left: 0.5px !important;
  margin-right: 0.5px !important;
  border-color: #fff !important;
  border-width: 2px !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
  color: white !important;
}

.MuiTooltip-tooltip {
  max-width: 400px !important;
}

.disable-selection {
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer */
  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}

.deal-date-card {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 0.8em;
  color: #171720;
}
